import Fieldset from 'components/_UI/Fieldset';
import Loader from 'components/_UI/Loader';
import Text from 'components/_UI/Text';
import masks from 'helpers/masks';
import regex from 'helpers/regex';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Column } from 'styled/alignment/Column';
import { Row } from 'styled/alignment/Row';
import addressApi from 'api/requests/address';
import { HorizontalCenter } from 'styled/alignment/Center';
import colors from 'theme/colors';
import fieldMessages from 'texts/messages/field';

export default function AddressInfo({
    trigger,
    errorAddress,
    setErrorAddress,
    setNameValue,
    nameValue,
    addressContent,
    setAddressContent,
    isEdit
}) {
    const [address, setAddress] = useState(null);
    const [addressLoading, setAddressLoading] = useState(false);
    const [cepFilled, setCepFilled] = useState(false);
    const [cepValue, setCepValue] = useState('');
    const [numberValue, setNumberValue] = useState('');
    const [complementValue, setComplementValue] = useState('');
    const [withoutNumber, setWithoutNumber] = useState(false);
    const [errors, setErrors] = useState(false);

    useEffect(() => {
        if (isEdit) {
            const { postalcode, number, name, complement, district, street } = isEdit;

            setCepValue(postalcode || '');
            setNumberValue(number || '');
            setNameValue(name || '');
            setComplementValue(complement || '');
            setWithoutNumber(!number);

            setAddress({
                street: {
                    postalcode,
                    name,
                    district,
                },
            });

            setAddressContent({
                address: isEdit,
            });

            setCepFilled(true);
        }
    }, [isEdit]);

    useEffect(() => {
        if (errorAddress && withoutNumber) {
            if (!nameValue) {
                setErrorAddress('Preencha o nome do endereço');
            }
        }

        if (errorAddress && !withoutNumber && addressContent?.address?.postalcode) {
            if (!addressContent?.address.number) {
                setErrorAddress('Preencha o número do endereço');
            }
        }

        if (trigger && !cepFilled && address) {
            setErrors({ ...errors, cep: 'CEP inválido' });
            return;
        }

        if (
            trigger &&
            !cepFilled &&
            addressContent?.address?.number &&
            addressContent?.address?.name
        ) {
            setErrors({ ...errors, number: fieldMessages.required('número') });
        }
    }, [trigger, errorAddress, withoutNumber, nameValue, cepFilled, address, addressContent, errors]);

    async function getAddress(payload) {
        setAddressLoading(true);

        try {
            const { data } = await addressApi.getByPostalCode(payload);

            setAddress(data);
            setAddressContent({ ...addressContent, address: data });
        } catch (error) {
            setErrors({ ...errors, cep: 'CEP inválido' });
            setAddress(false);
        } finally {
            setAddressLoading(false);
        }
    }

    function validateCEP(cep) {
        return regex.cep.test(cep);
    }

    function showAddressFields(value) {
        setCepValue(value);

        if (!validateCEP(value)) {
            if (trigger) {
                setErrors({ ...errors, cep: 'CEP inválido' });
            }
            return;
        }

        const postalCodeNumbers = value.replace(regex.onlyNumber, '');

        // if (isEdit && postalCodeNumbers.length === 9) {
        //     setErrors({ ...errors, cep: false });
        //     setCepFilled(true);
        //     getAddress(postalCodeNumbers);
        //     return
        // }

        if (postalCodeNumbers.length === 9) {
            setErrors({ ...errors, cep: false });
            setCepFilled(true);
            getAddress(postalCodeNumbers);
        }
    };

    function onChangeNumber(e) {
        if (!e) {
            setNumberValue('')
        }

        if (e) {
            setNumberValue(e);
        }
        setNameValue(null);
        setErrors({ ...errors, number: ' ' });

        if (withoutNumber && !address?.number) {
            setAddressContent({
                ...addressContent,
                address: {
                    postalcode: cepValue,
                    name: address?.street?.name,
                },
            });

            return;
        }

        setAddressContent({
            ...addressContent,
            address: {
                postalcode: cepValue,
                number: e,
            },
        });
    }

    return (
        <Column marginTop='18px'>
            <Text size='small' type='bold' textAlign={'center'} color={colors.occasionalPurple}>
                Endereço
            </Text>

            <Fieldset
                syncProp={true}
                label='CEP do local'
                layout='squired'
                mask={masks.cep}
                value={cepValue}
                name="cep"
                onChangeValue={(e) => showAddressFields(e)}
            />

            {errors && (
                <HorizontalCenter>
                    <Text color={colors.danger}>{errors?.cep}</Text>
                </HorizontalCenter>
            )}

            {address && (
                addressLoading ? (
                    <Loader />
                ) : (
                    <AddressFields visible={cepFilled} withoutNumber={withoutNumber}>
                        <Row>
                            <Fieldset
                                syncProp={true}
                                label='Bairro'
                                layout='squired'
                                value={address?.street?.district}
                                marginRight='14px'
                                // badgeText='Bairro'
                                disabled
                            />

                            <Fieldset
                                syncProp={true}
                                label='Rua'
                                layout='squired'
                                value={address?.street?.name}
                                // badgeText='Rua'
                                disabled
                            />
                        </Row>
                        <Row>
                            <Fieldset
                                syncProp={true}
                                label='Número'
                                layout='squired'
                                marginRight='14px'
                                disabled={withoutNumber}
                                value={numberValue}
                                onChangeValue={(e) => onChangeNumber(e)}
                                refresh={withoutNumber}
                            />
                            <Fieldset
                                syncProp={true}
                                label='Complemento'
                                layout='squired'
                                value={complementValue}
                                disabled={withoutNumber}
                                onChangeValue={(e) => {
                                    setComplementValue(e);
                                    setAddressContent({
                                        ...addressContent,
                                        address: {
                                            ...addressContent.address,
                                            complement: e,
                                        },
                                    });
                                }}
                            />
                        </Row>
                        <Row marginLeft='4px'>
                            <input
                                type="checkbox"
                                checked={withoutNumber}
                                onChange={({ target }) => {
                                    setWithoutNumber(target.checked);
                                    setAddressContent({
                                        ...addressContent,
                                        address: { ...addressContent?.address, number: null },
                                    });
                                    onChangeNumber(false);
                                }}
                            />
                            <Text
                                size='small'
                                color={colors.night}
                                paddingTop={'10px'}
                                paddingLeft={'8px'}
                            >
                                O endereço não possui número
                            </Text>
                        </Row>
                        {withoutNumber && (
                            <Fieldset
                                syncProp={true}
                                layout='squired'
                                label='Nome do local'
                                value={nameValue}
                                onChangeValue={(e) => {
                                    setNameValue(e);
                                    setAddressContent({
                                        ...addressContent,
                                        address: {
                                            ...addressContent.address,
                                            name: e,
                                        },
                                    });
                                }}
                            />
                        )}
                    </AddressFields>
                )
            )}

            {errors && (
                <HorizontalCenter marginTop='12px'>
                    <Text color={colors.danger}>{errors?.number}</Text>
                </HorizontalCenter>
            )}
            {errorAddress && (
                <HorizontalCenter marginTop='12px'>
                    <Text color={colors.danger}>{errorAddress}</Text>
                </HorizontalCenter>
            )}
        </Column>
    );
}

const AddressFields = styled.div`
    width: 100%;
    height: 0;
    visibility: hidden;
    opacity: 0;
    transition: all .2s ease-in;

    ${({ visible }) => visible && `
        visibility: visible;
        opacity: 1;
    `};

    ${({ visible, withoutNumber }) => visible && !withoutNumber && `
        height: 180px;
    `};

    ${({ visible, withoutNumber }) => visible && withoutNumber && `
        height: 210px;
    `};
`;
