import React, { useEffect, useState } from 'react'
import Text from 'components/_UI/Text';
import ButtonSelect from 'components/_UI/ButtonSelect';
import Fieldset from 'components/_UI/Fieldset';
import { Column } from 'styled/alignment/Column';
import { ButtonSelectItem, SelectContainer, SelectWrapper } from 'components/_UI/SelectsContent';
import styled from 'styled-components';
import colors from 'theme/colors';
import { Row } from 'styled/alignment/Row';
import Button from 'components/_UI/Button';
import { useEvent } from 'context/events/Event';
import BasicInfos from './parts/BasicInfos';
import CategoriesInfo from './parts/CategoriesInfo';
import AddressInfo from './parts/AddressInfo';
import ExtraInfo from './parts/ExtraInfo';
import { useForm } from 'react-hook-form';
import eventsApi from 'api/requests/events';
import date from 'helpers/date';
import ImageInfo from './parts/ImageInfo';
import eventTypes from 'theme/eventTypes';
import buttonText from 'texts/buttons/buttonText';
import { useUser } from 'context/user/User';
import tagsApi from 'api/requests/tags';
import { useTags } from 'context/events/Tags';
import imageUpload from 'api/requests/imageUpload';
import breakpoints from 'theme/breakpoints';
import validateInputFile from 'helpers/validate-input-file';
import allowedFilesTypes from 'texts/messages/allowedFilesTypes';


export default function EventForm({
    edit,
    isDraft,
    setCreateDraftClick,
    onLoading = () => false,
    onSuccess = () => false,
    onError = () => false,
}) {
    const { event, setEvent } = useEvent();
    const { user } = useUser();
    const { tags, setTags } = useTags();

    const [trigger, setTrigger] = useState(false);
    const [tagsError, setTagsError] = useState(false);
    const [errorImage, setErrorImage] = useState(false);
    const [requestError, setRequestError] = useState(false);
    const [tagsLoading, setTagsLoading] = useState(false);
    const [selectedTags, setSelectedTags] = useState(false);
    const [errorTypeImage, setErrorTypeImage] = useState(false)

    const [imageId, setImageId] = useState(null);
    const [isUploadImage, setIsUploadImage] = useState(false);
    const [thumb, setThumb] = useState(null);
    const [descriptionError, setDescriptionError] = useState(false);
    const [description, setDescription] = useState(null);
    const [descriptionWithHtml, setDescriptionWithHtml] = useState(null);
    const [errorTypeImageDescription, setErrorTypeImageDescription] = useState(null)
    const [pathImageDescription, setPathImageDescription] = useState(null)


    const {
        register,
        unregister,
        handleSubmit,
        reset,
        setValue,
        formState: { errors }
    } = useForm({
        defaultValues: !edit ? {} : {
            name: edit?.name || '',
            // description: edit?.description || '',
            ...datesToEdit(edit?.dates) || ''
        }
    });

    useEffect(() => {

        if (edit) {
            // getEventBySlug()
            setEvent(edit);
            setImageId(edit?.image_id)
            setThumb(edit?.thumb)

        }

        getCategories()
    }, [edit])


    async function getCategories() {
        if (tags) {
            return
        }
        setTagsLoading(true);
        try {
            const { data } = await tagsApi.get()
            setTags(data)
        } catch (error) {
            console.log('error')
        } finally {
            setTagsLoading(false);
        }
    }

    // async function getEventBySlug() {
    //     onLoading(true)

    //     try {
    //         const { data } = await eventsApi.getBySlug(edit?.slug)
    //         setEvent(data?.data)
    //     } catch (error) {
    //         console.log('error')
    //     } finally {
    //         onLoading(false)
    //     }
    // }

    function datesToEdit(data) {
        const transformedResponse = {};

        Object.keys(data).forEach(key => {
            const item = data[key];
            const index = Number(key) + 1;

            transformedResponse[`date${index}`] = date.format(item.date);
            transformedResponse[`start${index}`] = item.start;
            transformedResponse[`end${index}`] = item.end;
        });

        return transformedResponse;
    }

    function removeDuplicates(obj) {
        const result = obj;

        for (const key in result) {
            if (key.startsWith('start') || key.startsWith('end') || key.startsWith('date')) {
                delete result[key];
            }
        }

        return result;
    }

    /* 
      Quando foi feita essa função só Deus e eu sabiamos o que ela fazia.
      Agora só Deus sabe
    */
    function createDatesPayload(content) {
        const result = [];
        const regex = /^date\d+$/;
        let count = 0;

        for (const key in content) {
            if (regex.test(key)) {
                count++;
            }
        }

        for (let i = 1; i <= count; i++) {
            const dateKey = "date" + i;
            const startKey = "start" + i;
            const endKey = "end" + i;

            if (content[dateKey] && content[startKey] && content[endKey]) {
                const fomratedStartTime = content[startKey].split(':').length > 2 ? content[startKey] : `${content[startKey]}:00`
                const fomratedEndTime = content[endKey].split(':').length > 2 ? content[endKey] : `${content[endKey]}:00`

                result.push({
                    date: date.unformat(content[dateKey]),
                    start: fomratedStartTime,
                    end: fomratedEndTime
                });
            }
        }

        return result;
    }

    async function onToggleDelete() {
        setErrorImage(false)
        setErrorTypeImage(false)
        const id = imageId

        try {
            const { data } = await imageUpload.delete(id)
            setImageId(null)
            setEvent({ ...event, images_id: [] })
        } catch (error) {
            console.log(error);
        }
    }



    async function uploadImage(file) {

        setIsUploadImage(true)

        const validationFile = validateInputFile(file);

        if (!validationFile.isValid) {
            setErrorTypeImage(validationFile.message);
            setIsUploadImage(false);
            return
        }

        const formData = new FormData()
        formData.append('image', file)
        formData.append('folder', 'events')

        try {
            const { data } = await imageUpload.uploadWithFolder(formData)

            setImageId(data?.id)
            setEvent({ ...event, images_id: [data.id], images: data?.thumb })
            setThumb(data?.thumb)


        } catch (error) {
            setErrorImage(true)
        } finally {
            setIsUploadImage(false)
        }
    }


    async function uploadImageDescription(file) {

        setIsUploadImage(true)

        const validationFile = validateInputFile(file);

        if (!validationFile.isValid) {
            setErrorTypeImageDescription(validationFile.message);
            return
        }

        const formData = new FormData()
        formData.append('image', file)
        formData.append('folder', 'image_description_with_html')


        try {
            const { data } = await imageUpload.uploadWithFolder(formData)
            setPathImageDescription(data?.path)
        } catch (error) {
        } finally {
            setIsUploadImage(false)
        }
    }

    function isValidTags() {
        if (selectedTags) {
            if (selectedTags.length > 3) {
                setTagsError('max');
                return false;
            }

            if (selectedTags.length <= 0) {
                setTagsError('min');
                return false;
            }

            return true;
        }
    }

    function isValidAddress(address) {
        if (!address) {
            return false
        }

        if (!address.id) {
            return false
        }

        return true
    }

    function createAddressPayload(address) {
        const cleanedPostalcode = (address?.street?.postalcode || address?.postalcode)?.replace("-", "");

        if (address?.number) {
            return {
                postalcode: cleanedPostalcode,
                number: address?.number,
                complement: address?.complement
            }
        }

        if (address?.name) {
            return {
                postalcode: cleanedPostalcode,
                name: address?.name
            }
        }
    }

    function createPayload(data) {
        const isEstablishment = user?.role?.id == import.meta.env.VITE_ESTABLISHMENT_ROLE_ID;

        let payload = {
            ...event,
            dates: createDatesPayload(data),
            ...removeDuplicates(data),
            name: data?.name,
            description: description,
            description_with_html: descriptionWithHtml,
            address: createAddressPayload(event?.address),
            tags: selectedTags.map(el => el),
            draft: isDraft ? 1 : 0
            // has_tickets: event?.has_tickets
        }

        // setCreateDraftClick(0); 

        if (isEstablishment && user?.establishments[0].id) {
            payload = {
                ...payload,
                establishment_id: user?.establishments[0]?.id,
            }
        }

        if (!payload.link_ticket) {
            delete payload.link_ticket
        }

        if (!payload.link) {
            delete payload.link
        }

        if (!payload?.discount) {
            delete payload.discount
        }

        if (!payload?.discount_type) {
            delete payload.discount_type
        }

        if (!payload?.images?.length) {
            delete payload.images
        }

        if (payload?.images) {
            delete payload.images

        }

        if (edit) {
            if (payload?.image_id) {
                delete payload?.image_id
            }

            if (imageId) {
                payload.images_id = [imageId]
            }

            if (!imageId) {
                payload.images_id = []
            }

        }

        return payload
    }

    async function editEvent(content) {
        if (!isValidTags()) {
            return;
        }

        // if (!isValidAddress(content.address)) {
        //     return;
        // }

        onLoading(true);

        const payload = createPayload(content);

        const withTickets = event?.has_tickets;


        try {
            setTagsError(false);
            setRequestError(false);

            const { data } = await eventsApi.update(edit.slug, payload);
            setEvent(data)
            onSuccess({ ...data, has_tickets: withTickets }, 'edit')
        } catch (error) {
            setRequestError('Ocorreu um erro inesperado.')
            onError(true)
        } finally {
            onLoading(false)
            // setEvent({ ...event, tags: [] })
        }
    }

    function isValidDescription() {
        return description && description != '\n';
    }

    async function createEvent(content) {
        setDescriptionError(false);

        if (!isValidTags()) {
            return;
        }

        if (!isValidDescription()) {
            setDescriptionError(true);
            return;
        }

        onLoading(true);

        const payload = createPayload(content);
        const withTickets = event?.has_tickets;

        try {
            setTagsError(false);
            setRequestError(false);

            const { data } = await eventsApi.create(payload);
            const formatedData = {
                ...data,
                has_tickets: withTickets
            }
            setEvent(formatedData)
            onSuccess(formatedData, 'create')
            // onSuccess({ ...data, has_tickets: withTickets }, 'edit')
        } catch ({ response }) {
            if (response.status == 409) {
                setRequestError('Esse evento já existe.');
                onError(true);
                return
            }
            setRequestError('Ocorreu um erro inesperado.')
            onError(true)
        } finally {
            onLoading(false)
        }
    }


    return (
        <FormContainer onSubmit={handleSubmit(edit ? editEvent : createEvent)}>
            {
                requestError ? (
                    <Column fullyCentralized width='100%' height='100vh'>
                        <Column fullyCentralized width='300px'>
                            <Text color={colors.dangerLight} name='highlight' textAlign={'center'}>
                                {requestError}
                            </Text>
                        </Column>
                    </Column>
                ) : (
                    <>
                        <Text
                            name='title'
                            color={colors.occasionalPurple}
                        >
                            {edit ? 'Atualizar' : 'Criar'} {isDraft ? 'rascunho' : 'evento'}
                        </Text>

                        <BasicInfos
                            unregister={unregister}
                            register={register}
                            errors={errors}
                            edit={edit}
                            reset={reset}
                            setValue={setValue}
                            descriptionError={descriptionError}
                            onChangeDescription={e => setDescription(e)}
                            onChangeDescriptionWithHtml={e => setDescriptionWithHtml(e)}
                            uploadImageDescription={e => uploadImageDescription(e)}
                            errorTypeImageDescription={errorTypeImageDescription}
                            setErrorTypeImageDescription={setErrorTypeImageDescription}
                            pathImageDescription={pathImageDescription}
                        />
                        <ContentImage>
                            <ImageInfo
                                isEdit={edit}
                                onToggleImage={(e) => uploadImage(e)}
                                onDeleteFile={() => onToggleDelete()}
                                errorImage={errorImage}
                                setErrorImage={setErrorImage}
                                setErrorTypeImage={setErrorTypeImage}
                                errorTypeImage={errorTypeImage}
                                allowedFiles={allowedFilesTypes.images}
                            />
                        </ContentImage>

                        <CategoriesInfo
                            error={tagsError}
                            edit={edit?.tags}
                            tagsLoading={tagsLoading}
                            setSelecteds={setSelectedTags}
                        />

                        <AddressInfo trigger={trigger} edit={edit} />

                        <ExtraInfo register={register} errors={errors} edit={edit} />

                        <span onClick={() => setTrigger(true)}>
                            <Button
                                marginTop={'18px'}
                                paddingBottom={'30px'}
                                type="submit"
                                variant={isUploadImage ? 'disabled' : 'featured'}
                            >
                                {buttonText.next}
                            </Button>
                        </span>
                    </>
                )
            }
        </FormContainer>

    )
};

const FormContainer = styled.form`
    text-align: center;
`;

const ContentImage = styled(Column)`
    
    @media screen and (min-width: ${breakpoints.md}) {
        & > div {
            align-items: center;
            
            & > div > div:first-child {
                width: 60%;
                height: 160px;
                display: flex;
                align-items: center;
            }
        }
    }
    @media screen and (min-width: ${breakpoints.lg}) {
        & > div {
            align-items: center;
            & > div > div:first-child {
                height: 160px;
                width: 40%;
                display: flex;
                align-items: center;
            }
        }
    }
`;