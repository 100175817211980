import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import checkoutApi from 'api/requests/checkout';
import { FullyCentered } from 'styled/alignment/Center';
import Loader from 'components/_UI/Loader';
import Text from 'components/_UI/Text';
import Button from 'components/_UI/Button';
import { Column } from 'styled/alignment/Column';
import colors from 'theme/colors';
import { Row } from 'styled/alignment/Row';
import { useCheckout } from 'context/checkout/Checkout';
import TicketItem from './TicketItem';
import breakpoints from 'theme/breakpoints';
import { useCollapse } from 'context/general/Collapse';
import PaymentForm from '../payment/Payment';
import FreePaymentForm from '../payment/FreePayment';
import ProductItem from './ProductItem';
import UtilityHeader from 'components/_UI/UtilityHeader';
import EventDetail from 'modules/events/detail/EventDetail';
import buttonText from 'texts/buttons/buttonText';
import CouponModal from './CouponModal';
import currency from 'helpers/currency';

const TICKET_TYPE = 'ticket';
const PRODUCT_TYPE = 'product';

export default function CheckoutOrder({ event }) {
    const { checkout, setCheckout } = useCheckout();
    const { collapse, setCollapse } = useCollapse();

    const [loading, setLoading] = useState(true);
    const [products, setProducts] = useState([]);
    const [tickets, setTickets] = useState([]);
    const [totalPrice, setTotalPrice] = useState(0);
    const [originalPriceTotal, setOriginalPriceTotal] = useState(0)
    const [error, setError] = useState(false)
    const [getTicketsError, setGetTicketsError] = useState('')
    const [isCouponModalVisible, setIsCouponModalVisible] = useState(false)
    const [couponCode, setCouponCode] = useState('')
    const [couponId, setCouponId] = useState('')
    const [selectedTickets, setSelectedTickets] = useState([])
    const [selectedProducts, setSelectedProducts] = useState([])
    const [couponTrigger, setCouponTrigger] = useState(0)
    const [errorMinOrder, setErrorMinOrder] = useState(false)

    useEffect(() => {
        getOrder()
        setCollapse({
            ...collapse,
            withHideCity: true
        })
    }, [])

    async function getOrder(coupon, onSuccess) {
        setLoading(true);

        try {
            let config;

            if (coupon) {
                setCouponTrigger(Math.random(Infinity))
                config = {
                    params: {
                        coupon
                    }
                }
            }
            const { data } = await checkoutApi.getTickets(event?.slug, config);
            
            setTickets(data?.items?.filter(el => el.type === TICKET_TYPE));
            setProducts(data?.items?.filter(el => el.type === PRODUCT_TYPE));
            setCouponId(data?.coupon_id);

            if (onSuccess) {
                onSuccess()
            }

        } catch (error) {
            setGetTicketsError('Código promocional inválido.')
        } finally {
            setLoading(false);
        }
    }

    const handleUpdateTotal = (priceChange, originalPriceChange) => {
        setTotalPrice((prevTotal) => (parseFloat(prevTotal) + priceChange));
        setOriginalPriceTotal((prevTotal) => (parseFloat(prevTotal) + originalPriceChange));
    }

    async function buyTicket() {
        const payload = {
            ...checkout,
            event_id: event.id,
        }
        
        if (couponId) {
            payload.coupon_id = couponId
        }

        if(totalPrice < 5 && totalPrice !== 0) {
            setErrorMinOrder('Valor mínimo do pedido é de 5 reais!')
            return
        }

        if (!payload?.batches?.length) {
            setError(true)
            return
        }

        setLoading(true);

        try {
            const { data } = await checkoutApi.createOrder(payload);

            setCheckout({ ...checkout, orderId: data.id });

            let componentToRender

            if (Number(totalPrice) === 0) {
                componentToRender = () => (
                    <StyledCheckoutOrder>
                        <UtilityHeader title={'Pagamento'} onBack={goBack} />
                        <FreePaymentForm tickets={selectedTickets} event={event} />
                    </StyledCheckoutOrder>
                )
            } else {
                componentToRender = () => (
                    <StyledCheckoutOrder>
                        <UtilityHeader title={'Pagamento'} onBack={goBack} />
                        <PaymentForm tickets={selectedTickets} />
                    </StyledCheckoutOrder>
                )
            }

            setCollapse({
                ...collapse,
                arrowPosition: true,
                componentToRender
            });

        } catch ({ response }) {
            // console.log('error', response?.data)
        } finally {
            setLoading(false);
        }
    }

    function goBack() {
        setCollapse({
            withHideCity: false,
            arrowPosition: false,
            ...collapse,
            componentToRender: () => (
                <StyledCheckoutOrder>
                    <UtilityHeader title={event?.name} onBack={goToDetail} />
                    <CheckoutOrder event={event} />
                </StyledCheckoutOrder>
            )
        });
    }

    function goToDetail() {
        setCollapse({
            ...collapse,
            withHideCity: false,
            arrowPosition: false,
            componentToRender: () => <EventDetail data={event} />
        });
    }

    function toggleCouponModal() {
        setIsCouponModalVisible(!isCouponModalVisible)
        setGetTicketsError('')
    }

    function getOrderWithCoupon(coupon) {
        const onSuccess = () => {
            setGetTicketsError('')
            setCouponCode(coupon)
            toggleCouponModal()
            setTotalPrice(0)
            setOriginalPriceTotal(0)
            setCheckout({})
        }

        getOrder(coupon, onSuccess)
    }

    return (
        <>
            {
                loading
                    ? (
                        <FullyCentered height='80%'>
                            <Loader />
                        </FullyCentered>
                    )
                    : (
                        <>
                            <Container>
                                {/* <Text
                                    name='highlight'
                                    color={colors.occasionalPurple}
                                    type='bold'
                                    textAlign={'center'}
                                    marginTop={'14px'}
                                >
                                    {event?.name}
                                </Text> */}

                                <TicketsContainer
                                // isProducts={products.length > 0}
                                >

                                    {
                                        tickets.length > 0 && (
                                            <>
                                                <Text
                                                    type='bold'
                                                    textAlign={'center'}
                                                    marginTop={'25px'}
                                                    color={colors.nightDark}
                                                >
                                                    Ingressos
                                                </Text>

                                                <Tickets>
                                                    {
                                                        tickets.map(el => (
                                                            el?.batches.map(item => (
                                                                <TicketItem
                                                                    key={item.id}
                                                                    ticket={item}
                                                                    couponTrigger={couponTrigger}
                                                                    selectedTickets={selectedTickets}
                                                                    setSelectedTickets={setSelectedTickets}
                                                                    onChangeTotalValue={handleUpdateTotal}
                                                                />
                                                            ))
                                                        ))
                                                    }
                                                </Tickets>
                                            </>
                                        )
                                    }
                                </TicketsContainer>

                                <CouponButton>
                                    <Button
                                        onClick={toggleCouponModal}
                                        width={'300px'}
                                        variant='promoted'
                                    >
                                        {buttonText.addCoupon}
                                    </Button>
                                    {
                                        couponCode && <Text type="bold">Cupom aplicado: {couponCode}</Text>
                                    }
                                </CouponButton>

                                <TicketsContainer isTypeProduct>
                                    {
                                        products.length > 0 && (
                                            <>
                                                <Text type='bold' textAlign={'center'} marginTop={'26px'}>
                                                    Produtos
                                                </Text>

                                                <Text textAlign={'center'} color={colors.night}>
                                                    Compre agora e retire no evento.
                                                </Text>
                                                <Tickets>
                                                    {
                                                        products.map((el, index) => (
                                                            <ProductItem
                                                                key={index}
                                                                selectedProducts={selectedProducts}
                                                                setSelectedProducts={setSelectedProducts}
                                                                couponTrigger={couponTrigger}
                                                                product={el}
                                                                onChangeTotalValue={handleUpdateTotal}
                                                            />
                                                        ))
                                                    }
                                                </Tickets>
                                            </>
                                        )}
                                </TicketsContainer>

                            </Container>
                            <Footer>
                                {
                                    loading ? (
                                        <FullyCentered>
                                            <Loader />
                                        </FullyCentered>
                                    ) : (
                                        <>
                                            {
                                                originalPriceTotal === totalPrice &&
                                                <Text type='bold' color={colors.night} name='small'>
                                                    Total: R$ {totalPrice.toFixed(2)}
                                                </Text>
                                            }
                                            
                                            {
                                                originalPriceTotal !== totalPrice &&
                                                <Row fullyCentralized >
                                                    <Text
                                                        type='bold'
                                                        color={colors.night}
                                                        name='small'
                                                        marginRight={'4px'}
                                                    >
                                                        Total
                                                    </Text>
                                                    <Text
                                                        type='bold'
                                                        color={colors.night}
                                                        name='small'
                                                        decoration={'line-through'}
                                                        marginRight={'.5rem'}
                                                    >
                                                        R$ {currency.format(originalPriceTotal)}
                                                    </Text>
                                                    <Text
                                                        type='bold'
                                                        color={colors.success}
                                                        name='small'
                                                    >
                                                        R$ {totalPrice.toFixed(2)}
                                                    </Text>
                                                </Row>
                                            }

                                            {errorMinOrder && (
                                                <FullyCentered>
                                                    <Text color={colors.danger} name='small'> {errorMinOrder}</Text>
                                                </FullyCentered>
                                            )}

                                            <Button
                                                size='medium'
                                                variant={checkout?.batches?.length == 0 ? 'disabled' : 'featured'}
                                                onClick={() => buyTicket()}
                                            >
                                                Avançar
                                            </Button>
                                        </>
                                    )
                                }

                            </Footer>
                            <CouponModal
                                code={couponCode}
                                isOpen={isCouponModalVisible}
                                onClose={toggleCouponModal}
                                onSubmit={getOrderWithCoupon}
                                error={getTicketsError}
                            />
                        </>
                    )

            }
            {/* <Modal isOpen={error} onClose={() => setError(false)}>
                <Column verticalCenter>
                    <Text  color={colors.danger}>Você deve selecionar pelo menos 1 item!</Text>
                    <ContentButton>
                        <Button onClick={() => setError(false)} marginTop={'15px'}>
                            {buttonText.understood}
                        </Button>
                    </ContentButton>
                </Column>
            </Modal> */}

        </>
    )
}

const StyledCheckoutOrder = styled(Column)`
    height: 100vh;
`;


const Container = styled.div`
    width: 100%;
    height: 100vh;
    padding: 10px 30px;
    padding-bottom: 300px;
    background-color: ${colors.neutralLight};    
`;

const Footer = styled(FullyCentered)`
    flex-direction: column;
    width: 100%;
    padding: 10px 30px;
    position: fixed;
    bottom:0;
    left: 0;
    border: 1px solid #ddd;
    border-bottom-width: 0;
    border-radius: 20px 20px 0 0;
    background: ${colors.neutralLight};
    box-shadow: 0 -2px 8px #ddd;
    padding-bottom: 70px;
    
    @media screen and (min-width: ${breakpoints.lg}) {
        padding-bottom: 10px;
        margin-bottom: 0;
        width: 60%;

        & >  p {
            padding: 16px 0;
        }

        & > button { 
            width: 50%;
            margin-bottom: 6px;
        }
    }
`;

const CouponButton = styled(FullyCentered)`
    display: flex;
    flex-direction: column;
    max-width: 300px;
    margin: 0 auto;
`;

const TicketsContainer = styled(FullyCentered)`
    flex-direction: column;
    width: 100%;
    padding-bottom: 50px;

    /* ${({ isProducts }) => !isProducts && `
        padding-bottom: 50px;
    `}

    ${({ isProducts }) => isProducts && `
        padding-bottom: 50px;
    `} */
    
    ${({ isTypeProduct }) => isTypeProduct && `
        padding-bottom: 150px;
    `}
    
`;

const Tickets = styled(Column)`
    width: 100%;
    
    @media screen and (min-width: ${breakpoints.lg}) {
        width: 75%;
        background-color: ${colors.neutralLight};
        border: 1px solid ${colors.nightLight};
        border-radius: 8px;
        padding: 0 20px;
        box-shadow: 0px 1px 4px ${colors.nightLight};
    }
`;


const ContentButton = styled(Row)`
    width: 100%;
    @media screen and (min-width: 405px) {
        width: 270px;
    }
`