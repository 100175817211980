import ticketsApi from 'api/requests/tickets'
import Button from 'components/_UI/Button'
import Loader from 'components/_UI/Loader'
import Modal from 'components/_UI/Modal'
import Text from 'components/_UI/Text'
import React, { useState } from 'react'
import { FullyCentered } from 'styled/alignment/Center'
import { Row } from 'styled/alignment/Row'
import buttonText from 'texts/buttons/buttonText'
import colors from 'theme/colors'

export default function PublishModal({ data, visible, onClose, onPublished = () => false }) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);


    async function publishEvent() {
        setLoading(true);
        setError(false);

        try {
            await ticketsApi.removeDraft(data?.slug);
            onPublished(data?.name);
            onClose();
        } catch (error) {
            setError(true);
        } finally {
            setLoading(false);
        }
    }

    return (
        <Modal isOpen={visible} onClose={onClose}>
            <Text name='highlight'>Deseja realmente publicar o evento?</Text>
            <Text>Ao publicar este evento, ele ficará visível para todos os usuários da plataforma. Certifique-se de que todas as informações estão corretas antes de continuar.</Text>
            <Text>Você ainda poderá editar os detalhes do evento após a publicação, mas lembre-se de que alterações serão refletidas imediatamente para os usuários.</Text>

            {
                loading ? (
                    <FullyCentered marginTop='20px'>
                        <Loader />
                    </FullyCentered>
                ) : (
                    <Row marginTop='20px' width='100%'>
                        <Button
                            variant='secondary'
                            size='medium'
                            onClick={onClose}
                        >
                            {buttonText.cancel}
                        </Button>

                        <Button
                            variant='lightBlue'
                            size='medium'
                            marginLeft={'10px'}
                            onClick={publishEvent}
                        >
                            {buttonText.publish}
                        </Button>
                    </Row>
                )
            }

            {error && (
                <Text color={colors.danger} marginTop={'10px'}>
                    Ocorreu um erro ao publicar o evento.
                </Text>
            )}
        </Modal>
    )
}
