import React from 'react';
import CurrencyInput from 'react-currency-input-field';
import styled, { css } from 'styled-components';
import { StyledBadgeIcon, StyledBadgeText, StyledFieldset, StyledLabel } from 'styled/UI/StyledForm';
import colors from 'theme/colors';
import fonts from 'theme/fonts';
import sizes from 'theme/sizes';
import Icon from './Icon';

const CurrencyInputField = React.forwardRef(({
    value,
    placeholder,
    label,
    layout = 'straight',
    badgeText,
    badgeIcon,
    badgeIconColor,
    badgePosition,
    badgeIconBackground,
    isBadgeClickable,
    disabled,
    textIndent,
    name,
    onChange,
    onBadgeClick,
}, ref) => (
    <StyledFieldset>
        {label && (
            <StyledLabel layout={layout}>
                {label}
            </StyledLabel>
        )}

        <StyledInput
            placeholder={placeholder}
            value={value}
            decimalsLimit={2}
            intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
            layout={layout}
            textIndent={textIndent}
            ref={ref}
            disabled={disabled}
            onValueChange={onChange}
            name={name}
        />

        {badgeText && (
            <StyledBadgeText
                position={badgePosition}
                clickable={isBadgeClickable && !disabled}
                layout={layout}
                onClick={() => onBadgeClick()}
            >
                {badgeText}
            </StyledBadgeText>
        )}

        {badgeIcon && (
            <StyledBadgeIcon
                id='badge_icon'
                layout={layout}
                bgColor={badgeIconBackground}
                position={badgePosition}
                clickable={isBadgeClickable && !disabled}
                onClick={() => onBadgeClick()}
            >
                <Icon name={badgeIcon} color={badgeIconColor} />
            </StyledBadgeIcon>
        )}

    </StyledFieldset>
));

export default CurrencyInputField;

const StyledInput = styled(CurrencyInput)`
    width: 100%;
    outline-color: transparent;
    transition: all ease .25s;
    background: transparent;
    outline: transparent;
    color: ${colors.night};

    ${({ disabled }) => disabled && css`
        pointer-events: none;
    `}

    ${({ icon }) => icon && css`
        padding-left: ${sizes.large} !important;
    `}

    ${({ textIndent }) => textIndent && css`
        text-indent: ${textIndent};
        
        &::placeholder {
            text-indent: ${textIndent};
        }
    `}

    &::placeholder {
        color: ${colors.nightLight};
    }

    ${({ layout }) => layout === 'straight' && css`
        border: none;
        border-bottom: ${sizes.quark} solid ${colors.nightLight};
        padding: ${sizes.quark};
        font-weight: ${fonts.weight.bold};
        
        &::placeholder {
            font-weight: ${fonts.weight.regular};
        }

        &:focus {
            border-bottom: 2px solid ${colors.night}
        }
    `}

        ${({ layout, disabled }) => layout === 'squired' && disabled && css`
        background: transparent;
        border: 1px solid ${colors.nightLight};
        padding: 10px;
        pointer-events: none;
        border-radius: 4px;
        margin-top: 5px;
        opacity: .8;
        pointer-events: none;
    `}
    
    ${({ layout }) => layout === 'squired' && css`
        background: transparent;
        border: 1px solid ${colors.nightLight};
        padding: 10px;
        border-radius: 4px;
        margin-top: 5px;

        &:focus {
            border: 1px solid ${colors.night}
        }
    `}

    ${({ layout }) => layout === 'straight-center' && css`
        border: none;
        border-bottom: ${sizes.quark} solid ${colors.nightLight};
        padding: ${sizes.quark};
        font-weight: ${fonts.weight.bold};
        text-align: center;
        color: ${colors.neutralDark};
        
        &::placeholder {
            font-weight: ${fonts.weight.regular};
            text-align: center;
            color: ${colors.nightLight};
        }

        &:focus {
            border-bottom: 2px solid ${colors.neutralDark}
        }
    `}
   
    ${({ layout }) => layout === 'rounded' && css`
        border: 2px solid #ccc;
        border-radius: 30px;
        padding: ${sizes.mini};
        font-weight: ${fonts.weight.bold};
        background: transparent;
        color: ${colors.nightDark};

        &:focus {
            border: ${sizes.quark} solid ${colors.night};
        }
    `}
`