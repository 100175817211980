import React, { useEffect, useState } from 'react'
import Button from 'components/_UI/Button'
import styled from 'styled-components'
import { Column } from 'styled/alignment/Column';
import breakpoints from 'theme/breakpoints';
import Text from 'components/_UI/Text';
import { File, PlusSquare } from 'react-feather';
import colors from 'theme/colors';


export default function RightSlotEvent({ onCreateEventClick = () => false, onCreateDraftClick = () => false, hasEvents }) {
    return (
        <RightSlotContainer>
            <ButtonContainer>
                <Button
                    marginBottom='0'
                    category='squiredBorded'
                    variant='secondary'
                    style={{ border: `1px solid ${colors.primary}` }}
                    onClick={() => onCreateDraftClick()}
                >
                    <File color={colors.primary} size={18} />
                    <Text color={colors.primary} marginLeft={'6px'} paddingBottom={0}>
                        Criar rascunho
                    </Text>
                </Button>
            </ButtonContainer>

            {hasEvents &&
                <ButtonContainer>
                    <Button
                        marginBottom='0'
                        category='squiredBorded'
                        onClick={() => onCreateEventClick()}
                    >
                        <PlusSquare color={colors.neutral} size={18} />
                        <Text color={colors.neutral} marginLeft={'6px'} paddingBottom={0}>
                            Criar evento
                        </Text>
                    </Button>

                </ButtonContainer>
            }
        </RightSlotContainer>
    )
}

const RightSlotContainer = styled.div`
    width: 100%;
    height: 100%;

    & > div:last-child  {
        margin-top: 14px !important;
    }

    & > button {
        margin-bottom: 0 !important;
        
    }

    @media screen and (min-width: ${breakpoints.md}){
        width: auto;
        display: flex;
        flex-direction: row;

        & > div:last-child  {
            margin-left: 14px !important;
            margin-top: 0 !important;
        }
    }

`;

const ButtonContainer = styled.div`
    width: 100%;
    & > button {
            height: 35px;
            padding: 0;
    }
    
    @media screen and (min-width: ${breakpoints.md}){
        width: 155px;
        height: 100%;

        & > button {
            height: 35px;
            padding: 0;
        }
    }
    @media screen and (min-width: ${breakpoints.lg}){
        & > button {
            height: 35px;
            /* margin-right: 3%;

            &:first-child{
                margin-left: 0;
            } */
        }
        /* margin-right: 11px; */
    }
    @media screen and (min-width: ${breakpoints.xl}){
        & > button {
            height: 35px;
        }
        margin-right: 0;
    }

    
`;

const FormContainer = styled(Column)`
    padding: 40px;
    height: 100%;
`;