import productsApi from 'api/requests/products';
import ticketsApi from 'api/requests/tickets';
import Text from 'components/_UI/Text';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FullyCentered } from 'styled/alignment/Center';
import { Column } from 'styled/alignment/Column';
import colors from 'theme/colors';
import ProductSelect from './ProductSelect';
import Loader from 'components/_UI/Loader';
import breakpoints from 'theme/breakpoints';
import Button from 'components/_UI/Button';
import { useEvent } from 'context/events/Event';

export default function ProductsToEvent({
    edit,
    availableProducts,
    isDraft,
    onSuccess = () => false
}) {
    const [loading, setLoading] = useState(false);
    const [content, setContent] = useState(null);
    const [selectedProductIds, setSelectedProductIds] = useState([]);
    const [products, setProducts] = useState([]);

    const { event } = useEvent();

    useEffect(() => {
        getProducts();
    }, [event]);

    const getProducts = () => {
        setProducts(availableProducts);
        setContent(availableProducts);

        if (edit && event?.products?.length) {
            const preSelectedProductIds = event.products.map(product => product.name);
            setSelectedProductIds(preSelectedProductIds);
        }
    };

    const handleSelect = (id) => {
        setSelectedProductIds((prevSelected) =>
            prevSelected.includes(id)
                ? prevSelected.filter(productId => productId !== id)
                : [...prevSelected, id]
        );
    };

    const handleUpdateProduct = (id, updatedValues) => {
        setProducts((prevProducts) =>
            prevProducts.map(product =>
                product.id === id ? { ...product, ...updatedValues } : product
            )
        );
    };

    const handlePayload = () => {

        const preSelectedProducts = event?.products?.map(product => product.name) || [];

        const payload = products
            .filter(product => selectedProductIds.includes(product.name))
            .map(product => {
                const payload = {
                    event_id: event?.id,
                    price: parseFloat(product.price),
                    name: product.name,
                    quantity: product.quantity,
                    description: product?.description,
                    type: 'product',
                    image_id: product.image_id
                }

                if (edit && preSelectedProducts.includes(product.name)) {
                    const selected = event?.products.find(el => el.name === product.name);
                    payload.id = selected.id
                }

                if (!payload?.image_id) {
                    delete payload.image_id;
                }

                // if (edit) {
                //     return {
                //         ...payload,
                //         batches: payload
                //     }
                // }

                return payload
            });

        if (!payload?.description) {
            delete payload.description;
        }

        if (!payload?.image_id) {
            delete payload.image_id;
        }

        const formatedPayload = payload.map(el => {
            let item = el;
            if (!item.description) {
                delete item.description
            }

            return item
        })

        return formatedPayload
    };

    async function updateProducts() {
        const payload = {
            type: 'products',
            event_id: event?.id,
            products: handlePayload()
        };

        setLoading(true);

        try {
            const { data } = await ticketsApi.updateBulk(payload);
            onSuccess({
                ...event,
                ...data,
            })
        } catch (error) {
            // console.log('error')
        } finally {
            setLoading(false);
        }
    }

    async function createProducts() {
        const payload = {
            products: handlePayload()
        };

        setLoading(true);

        if (!payload?.products?.length) {
            onSuccess(event);

            if (!isDraft) {
                removeDraft();
            }
            return
        }

        try {
            const { data } = await ticketsApi.createBulk(payload);
            onSuccess({
                ...event,
                ...data,
            })

            if (!isDraft) {
                removeDraft();
            }
        } catch (error) {
            // console.log('error')
            setLoading(false);
        }
    }

    async function removeDraft() {
        try {
            await ticketsApi.removeDraft(event?.slug);
        } catch (error) {
            // console.log('error')
        } finally {
            setLoading(false);
        }
    }



    return (
        <Column width='100%'>
            <FullyCentered>
                <Text name='title' color={colors.occasionalPurple}>
                    Selecione os produtos do evento
                </Text>
            </FullyCentered>

            {
                loading ? (
                    <FullyCentered>
                        <Loader />
                    </FullyCentered>
                ) : (
                    <ProductsList>
                        {
                            content?.map(el => (
                                <ProductSelect
                                    data={el}
                                    key={el.id}
                                    edit={edit}
                                    selected={selectedProductIds.includes(el.name) && el}
                                    products={event?.products}
                                    onSelect={() => handleSelect(el.name)}
                                    onUpdate={handleUpdateProduct}
                                />
                            ))
                        }
                    </ProductsList>
                )}

            <ButtonsContainer>
                {
                    loading ? (
                        <FullyCentered>
                            <Loader />
                        </FullyCentered>
                    ) : (
                        <FullyCentered marginTop='40px'>
                            <Column width='280px'>
                                <Button variant='secondary'>
                                    Voltar
                                </Button>
                            </Column>
                            <Column width='280px'>
                                <Button onClick={!edit ? createProducts : updateProducts}>
                                    Avançar
                                </Button>
                            </Column>
                        </FullyCentered>
                    )
                }
            </ButtonsContainer >
        </Column >
    );
}


const ProductsList = styled(Column)`
    margin-top: 30px;
    
    @media screen and (min-width: ${breakpoints.md}){
        flex-direction: row;
        flex-wrap: wrap;

        & > div {
            width: 31.5%;
            margin-left: 1%;

            &:first-child {
                margin-left: 0;
            }
        }
        /* justify-content: space-between; */
    }
`;

const ButtonsContainer = styled(FullyCentered)`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding-bottom: 14px;
    background: ${colors.neutralLight};
    border-top: 1px solid ${colors.nightLight};
    border-radius: 12px;
    box-shadow: 0 -2px 8px #ddd;
    z-index: 99;
`;