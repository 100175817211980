export default function downloadBlob(data, fileName, format) {
    const MIME_TYPES = {
        xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        xls: 'application/vnd.ms-excel',
        pdf: 'application/pdf',
        csv: 'text/csv;charset=utf-8;'
    };
    
    const blobType = MIME_TYPES[format] || 'application/octet-stream';
    const blob = new Blob([data], { type: blobType });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');

    link.href = url;
    link.setAttribute('download', fileName);

    document.body.appendChild(link);

    link.click();
    link.remove();
}
