import Icon from 'components/_UI/Icon';
import Text from 'components/_UI/Text';
import currency from 'helpers/currency';
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Column } from 'styled/alignment/Column';
import { Row } from 'styled/alignment/Row'
import breakpoints from 'theme/breakpoints';
import colors from 'theme/colors';

export default function ProductCard({ 
    data,  
    onDropdownAction,
    dropDownVisible,
    onMoreClick,
    onProductClick = () => false,
    visibleMoreAction = false,
    isVisiblePrice = false
}) {
    const [price, setPrice] = useState(null)
    const [toggleDropdown, setToggleDropdown] = useState(null);
    const [status, setStatus] = useState(false)

    function renderActions() {
        setToggleDropdown(!toggleDropdown)
        onMoreClick()
    }

    useEffect(() => {
        
        if (data) {
            data?.batches?.forEach((el) => {
                if (el?.quantity_available > 0 && el?.is_active == 1) {
                    setStatus('disponível');
                }
                if (el?.quantity_available == 0 ) {
                    setStatus('esgotado');
                }
            })
            setPrice(parseFloat(data?.price))
        }
    }, [data]) 
    
    return (
        <Card  
            status={status} 
            onClick={(e) => onProductClick(e)}>
            {
                visibleMoreAction && 
                    <ContentMore onClick={(e) => e.stopPropagation(e)}>
                        <StyledIconMore onClick={() => renderActions()}>
                            <MoreCircle />
                            <MoreCircle />
                            <MoreCircle />
                        </StyledIconMore>
                    </ContentMore>
            }
            {(toggleDropdown && dropDownVisible) && (
                <DropDownActions onClick={(e) => e.stopPropagation()}>
                    <StyledActions onClick={() => onDropdownAction('edit')}>
                        <StyledIconAction edit>
                            <Icon color={colors.primary} name='edit' />
                        </StyledIconAction>
                        <Text name='small' color={colors.primary}>Editar</Text>
                    </StyledActions>

                    <StyledActions onClick={() => onDropdownAction('delete')}>
                        <StyledIconAction>
                            <Icon color={colors.danger} name='delete' />
                        </StyledIconAction>
                        <Text name='small' color={colors.danger}>Excluir</Text>
                    </StyledActions>
                </DropDownActions>
            )}
            <Picture>
                {
                    data?.image ? (
                        <StyledImage src={data?.image} />
                    ) : (
                        <StyledImage src={'../../assets/images/placeholder-products.png'} />
                    )
                }
            </Picture>

            <ContentInfo>
                {
                    isVisiblePrice &&
                    <TextStatus>{status}</TextStatus>
                }


                <Text color={status === 'disponível' && colors.occasionalPurple || status === 'esgotado' && colors.nightLight || !status && colors.occasionalPurple} ellipsisAt={'95%'} type='bold' name='highlight' marginTop={'6px'}>
                    {data.name}
                </Text>

                {
                    !isVisiblePrice ? (
                        <>
                        <Text marginTop={'5px'} paddingBottom={'0px'}>Preço pré definido: <b>{currency?.format(parseFloat(data?.price))}</b> </Text>  
                    
                        <Text marginTop={'5px'} paddingBottom={'0px'} > Quantidade pré definida: {data?.quantity}</Text>
                        </>
                    ) : (
                        
                        <TextPrice status={status}>{currency?.format(parseFloat(data?.price))}</TextPrice>  
                        
                    )


                }

                
            </ContentInfo>

            {
                data.popular && (
                    <Popular>Mais vendido</Popular>
                )
            }
        </Card>
    )
}

const Card = styled(Row)`
   
    border-radius: 6px;
    position: relative;
    box-shadow: 0 2px 7px #ddd;  
    height: 130px;
    max-width: 100%;
    cursor: pointer;
    transition: all ease-in-out .25s;
    background: ${colors.neutralLight};
    &:hover {
        transform: scale(1.03);
    }
    ${({status}) => status == 'disponível' && `
        background: ${colors.neutralLight};
    `}
    ${({status}) => status == 'esgotado' && `
        opacity: 0.6;
    `}
`;

const Picture = styled.picture`
    width: 35%;
    height: 100%;
`;

const StyledImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 6px 0 0 6px;
`;

const ContentInfo = styled(Column)`
    width: 65%;
    padding: 10px;
`;


const Popular = styled.div`
    position: absolute;
    right: 0;
    top: -14px;
    padding: 6px 10px;
    background-color: ${colors.gold};
    color: ${colors.neutral};
    border-radius: 30px;
    font-size: 10px;
`;

const RowItem = styled(Row)`
    justify-content: space-between;
`;

const StyledBackground = styled.img`
    width: 100%;
    height: 200px;
    object-fit: cover;
`;

const MoreCircle = styled.div`
    width: 8px;
    height: 8px;
    background: ${colors.night};
    border: 1px solid ${colors.neutralLight};
    border-radius: 30px;
`;

const StyledIconMore = styled(Row)`
    position: absolute;
    height: 22px;
    width: 30px;
    right: 10px;
    top: 0;
    margin-top: 0;
    align-items: center;
    justify-content: space-between;
`;

const DropDownActions = styled(Column)`
    display: flex;
    width: 100px;
    overflow: auto;
    position: absolute;
    align-items: center;
    border-radius: 8px;
    background: #FFFDFD;
    top: 14%;
    right: 10px;
    min-height: auto;
    padding: 8px;
    max-height: 200px;
    border: 1px solid ${colors.nightLight};
`;

const StyledActions = styled(Row)`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 4px;
    margin-top: 5px;
    text-align: left;

    &:hover {
        background: ${colors.primaryLight};

        & > div > svg {
            fill: ${colors.neutral} !important;
        }

        & > p {
            color: ${colors.neutral} !important;
        }
    }

    & > p {
        font-weight: bold;
        color: ${colors.primaryDashboard};
        padding-bottom: 0;
    }
`;

const StyledIconAction = styled.div`
    width: 20px;
    height: 20px;
    margin-right: 4px;
    ${({ edit }) => edit && `
        margin-right: 4px;
        width: 20px;
        height: 15px;
    `}
`;

const TextPrice = styled.p`
    padding-bottom: 0px;
    margin-top: 5px;
    font-weight: bold;
    color: ${colors.primaryDashboard};
    ${({status}) => status === 'esgotado' && `
        text-decoration: line-through;
        color: ${colors.primaryDashboard};
    `}
    ${({status}) => status === 'disponível' && `
        text-decoration: normal;
        ${colors.primaryDashboard};
    `}
`;

const TextStatus = styled.p`
    font-size: 10px;
    text-transform: capitalize;
    color: ${colors.primaryDashboard};
`;

const ContentMore = styled.div``;
