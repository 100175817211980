import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Fieldset from 'components/_UI/Fieldset';
import Text from 'components/_UI/Text';
import TextArea from 'components/_UI/TextArea';
import fieldValidations from 'helpers/fieldValidations';
import masks from 'helpers/masks';
import { HorizontalCenter } from 'styled/alignment/Center';
import { Column } from 'styled/alignment/Column';
import colors from 'theme/colors';
import { useEstablishment } from 'context/establishments/Establishment';
import Switch from 'components/_UI/Switch';
import establishmentTypes from 'theme/establishmentTypes';
import { cnpj as cnpjValidator } from 'cpf-cnpj-validator';
import sizes from 'theme/sizes';


export default function BasicInfo({ register, errors, isEdit, basicInfo, setBasicInfo = () => false }) {
  const [isPaid, setIsPaid] = useState(false);
  const [isAdulthood, setIsAdulthood] = useState(false);
  const { establishment, setEstablishment } = useEstablishment();

  useEffect(() => {

    if (isEdit) {
      setBasicInfo({
        adulthood: isEdit?.adulthood,
        classification_id: isEdit?.classification_id,
      })
      setIsAdulthood(isEdit?.adulthood)
      setIsPaid(isEdit?.classification.id == establishmentTypes?.paid?.id ? establishmentTypes?.paid?.id : establishmentTypes?.free?.id)

      return
    }

    setBasicInfo({
      adulthood: isAdulthood,
      classification_id: isPaid ? establishmentTypes?.paid?.id : establishmentTypes?.free?.id
    })

    // setEstablishment({
    //   ...establishment,
    //   adulthood: false,
    //   classification_id: establishmentTypes?.free?.id,
    // });
    setIsPaid(false);
  }, [isEdit])


  const handleType = (checked) => {
    setBasicInfo({
      ...basicInfo,
      classification_id: checked ? establishmentTypes.paid.id : establishmentTypes.free.id,
    });
    setIsPaid(checked);
  };

  const handleAdulthood = (check) => {
    setBasicInfo({
      ...basicInfo,
      adulthood: check ? 1 : 0,
    });
    setIsAdulthood(check)
  };

  return (
    <StyledBasicInfos>
      <Text size='small' type='bold' color={colors.occasionalPurple}>Informações básicas</Text>

      <Fieldset
        label='Nome do estabelecimento'
        layout='squired'
        marginTop='10px'
        name='nameEstablishment'
        marginBottom='10px'
        validations={fieldValidations.generic('nome do estabelecimento')}
        register={register}
      />

      {errors && (
        <HorizontalCenter marginTop={'10px'}>
          <Text color={colors.danger}>{errors.nameEstablishment?.message}</Text>
        </HorizontalCenter>
      )}

      <Column>
        <Text color={colors.nightDark} type='bold' name='small'>Descrição</Text>
        <TextArea
          name='description'
          squired={true}
          validations={fieldValidations.generic('descrição', 0, 120)}
          register={register}
        />
      </Column>

      {errors && (
        <HorizontalCenter marginTop={'10px'}>
          <Text color={colors.danger}>{errors.description?.message}</Text>
        </HorizontalCenter>
      )}

      {/* <Fieldset
        label='CNPJ'
        layout='squired'
        marginTop={sizes.normal}
        name="cnpj"
        register={register}
        mask={masks.cnpj}
        validations={{
            required: 'CNPJ é obrigatório',
            validate: value => cnpjValidator.isValid(value) || 'CNPJ inválido'
        }}
      />
      {errors.cnpj && (
        <HorizontalCenter marginTop={'10px'}>
          <Text color={colors.danger}>{errors.cnpj.message}</Text>
        </HorizontalCenter>
      )} */}


      <Fieldset
        label='Telefone'
        layout='squired'
        name='phone'
        mask={masks.phone}
        marginTop='10px'
        register={register}
      />

      <Fieldset
        label='Link delivery'
        layout='squired'
        name='delivery'
        marginTop='10px'
        register={register}
      />


      <Switch
        marginTop='10px'
        size={'30px'}
        checked={isPaid}
        onChange={handleType}
        id={'typeEstablishment'}
        label={'O estabelecimento cobra entrada'}
      />

      <Switch
        marginTop='10px'
        size={'30px'}
        checked={isAdulthood}
        id={'adulthood'}
        onChange={handleAdulthood}
        label={'O estabelecimento é para maiores de 18 anos?'}
      />
    </StyledBasicInfos>
  );
}

const StyledBasicInfos = styled(Column)`
  margin-top: 12px;
  width: 100%;
  align-items: center;
`;

const StyledType = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  & > label {
    display: flex;
    align-items: center;
    width: 30px;
  }
`;
