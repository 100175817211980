import React, { useEffect, useState } from 'react';
import { Column } from 'styled/alignment/Column.js';
import FieldSet from 'components/_UI/Fieldset';
import Text from 'components/_UI/Text';
import styled from 'styled-components';
import breakpoints from 'theme/breakpoints';
import { useEstablishment } from 'context/establishments/Establishment';
import colors from 'theme/colors';
import { FullyCentered } from 'styled/alignment/Center';
import Loader from 'components/_UI/Loader';

export default function SocialMedia({ data, setSocialMediaContent, isEdit, loading }) {
  const [socialMedia, setSocialMedia] = useState([]);

  useEffect(() => {
    if (!socialMedia.length) {
      setSocialMedia(data);
    }

    if (isEdit && isEdit.length > 0) {
      const mappedEditData = data.map((item) => {
        const editItem = isEdit.find(el => el.id === item.social_media_id);
        return editItem ? { ...item, url: editItem.url } : item;
      });
      setSocialMedia(mappedEditData);
    } else if (!socialMedia.length) {
      setSocialMedia(data);
    }
  }, [data, isEdit]);

  useEffect(() => {
    const filteredSocialMedia = socialMedia.filter(el => typeof el.url === 'string' && el.url.trim() !== '');
    setSocialMediaContent(filteredSocialMedia);
  }, [socialMedia, setSocialMediaContent]);

  const handleSocialMedialChange = (id, value) => {
    setSocialMedia((prevState) =>
      prevState.map((el) =>
        el.social_media_id === id ? { ...el, url: value } : el
      )
    );
  };

  return (
    <Container>
      <Text size="small" type="bold" textAlign={'center'} color={colors.occasionalPurple}>
        Redes sociais
      </Text>

      {loading && data.length ? (
        <FullyCentered>
          <Loader />
        </FullyCentered>
      ) : (
        <ContentSocialMedia>
          {socialMedia?.map((el) => (
            <FieldSet
              key={el.social_media_id}
              label={`Link do ${el?.label}`}
              layout="squired"
              marginTop="10px"
              syncProp={true}
              value={el?.url || ''}
              onChangeValue={(e) => handleSocialMedialChange(el.social_media_id, e)}
            />
          ))}
        </ContentSocialMedia>
      )}
    </Container>
  );
}

const Container = styled(Column)`
  width: 100%;
`;

const ContentSocialMedia = styled(Column)`
  width: 100%;
  @media screen and (min-width: ${breakpoints.sm}) {
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  & > div {
    @media screen and (min-width: ${breakpoints.sm}) {
      width: 49%;
    }
  }
`;
