import fieldMessages from "texts/messages/field";
import regex from "./regex";

const fieldValidations = {
    name: (field = 'nome', minLength = 2, maxLength = 30) => {
        return {
            required: fieldMessages.required(field),
            minLength: {
                value: minLength,
                message: fieldMessages.minValue(minLength)
            },
            maxLength: {
                value: maxLength,
                message: fieldMessages.maxValue(maxLength)
            },
            pattern: {
                value: regex.onlyAlphabetic,
                message: fieldMessages.onlyAlphabetic
            }
        }
    },

    generic: (field = 'nome', minLength = 2, maxLength = 1200) => {
        return {
            required: fieldMessages.required(field),
            minLength: {
                value: minLength,
                message: fieldMessages.minValue(minLength)
            },
            maxLength: {
                value: maxLength,
                message: fieldMessages.maxValue(maxLength)
            },

        }
    },

    description: (field = 'descrição', maxLength = 120) => {
        return {
            maxLength: {
                value: maxLength,
                message: fieldMessages.maxValue(maxLength)
            },

        }
    },

    number: (field = 'valor', minLength = 1, maxLength = 15) => {
        return {
            required: fieldMessages.required(field),
            minLength: {
                value: minLength,
                message: fieldMessages.minValue(minLength)
            },
            maxLength: {
                value: maxLength,
                message: fieldMessages.maxValue(maxLength)
            },
            pattern: {
                value: regex.onlyNumber,
                message: fieldMessages.onlyNumber
            }
        }
    },

    quantity: () => ({
        required: "Quantidade é obrigatória",
        pattern: {
            value: /^[0-9]+$/,
            message: "Quantidade deve ser um número",
        },
    }),

    addressNumber: (field = 'nome', minLength = 1, maxLength = 20) => {
        return {
            required: fieldMessages.required(field),
            minLength: {
                value: minLength,
                message: fieldMessages.minValue(minLength)
            },
            maxLength: {
                value: maxLength,
                message: fieldMessages.maxValue(maxLength)
            },
        }
    },

    link: (field = 'link', minLength = 3, maxLength = 300) => {
        return {
            minLength: {
                value: minLength,
                message: fieldMessages.minValue(minLength)
            },
            maxLength: {
                value: maxLength,
                message: fieldMessages.maxValue(maxLength)
            },
            pattern: {
                value: regex.link,
                message: fieldMessages.invalidUrl
            }
        }
    },

    date: (field = 'data') => {
        return {
            required: fieldMessages.fillCorrectly(field),
            pattern: {
                value: regex.date,
                message: fieldMessages.invalidFemaleField(field)
            },
            validate: {
                validDateRange: (value) => {
                    const today = new Date();
                    const todayDateOnly = new Date(today.getFullYear(), today.getMonth(), today.getDate())
                    const hundredYearsLater = new Date();
                    hundredYearsLater.setFullYear(today.getFullYear() + 100);

                    const [day, month, year] = value.split('/'); // Assumindo DD/MM/YYYY
                    const inputDate = new Date(year, Number(month) - 1, day);

                    if (inputDate < todayDateOnly) {
                        return 'A data deve ser maior ou igual ao dia de hoje';
                    }
                    if (inputDate > hundredYearsLater) {
                        return 'Ano inválado';
                    }
                    return true;
                }
            }
        };
    },

    shortDate: (field = 'data') => {
        return {
            required: fieldMessages.fillCorrectly(field),
            pattern: {
                value: regex.shortDate,
                message: fieldMessages.invalidFemaleField(field)
            }
        };
    },

    email: (field = 'e-mail') => {
        return {
            required: fieldMessages.required(field),
            pattern: {
                value: regex.email,
                message: fieldMessages.invalidMaleField(field)
            }
        };
    },

    phone: (field = 'telefone') => {
        return {
            required: fieldMessages.required(field),
            pattern: {
                value: regex.phone,
                message: fieldMessages.invalidMaleField(field)
            }
        };
    },

    cpf: (field = 'CPF', required = true) => {
        return {
            ...(required && { required: fieldMessages.required(field) }),
            pattern: {
                value: regex.cpf,
                message: fieldMessages.invalidMaleField(field)
            }
        };
    },

    cnpj: (field = 'CNPJ', required = true) => {
        return {
            ...(required && { required: fieldMessages.required(field) }),
            pattern: {
                value: regex.cnpj,
                message: fieldMessages.invalidMaleField(field)
            }
        };
    },

    code: (field = 'code', minLength = 6) => {
        return {
            required: fieldMessages.required(field),
            minLength: {
                value: minLength,
                message: fieldMessages.invalidMaleField(field)
            }
        };
    },

    password: (field = 'senha') => {
        return {
            required: fieldMessages.required(field),
            pattern: {
                value: regex.password,
                message: fieldMessages.password(field)
            }
        };
    },

    passwordWithoutPatterns: (field = 'senha') => {
        return {
            required: fieldMessages.required(field),
        };
    },

    cepPatterns: (field = 'CEP', minLength = 9) => {
        return {
            required: fieldMessages.required(field),
            minLength: {
                value: minLength,
                message: fieldMessages.invalidMaleField(field)
            },
            pattern: {
                value: regex.cep,
                message: fieldMessages.fillCorrectly(field)
            }
        };
    },

    time: (field = 'horário') => {
        return {
            required: fieldMessages.required(field),
            pattern: {
                value: /^([01]\d|2[0-3]):([0-5]\d)$/,
                message: fieldMessages.invalidTime(field) 
            }
        };
    },
    
    timeWithoutRequired: (field = 'horario') => {
        return {
            pattern: {
                value: {
                    value: regex.time,
                    message: fieldMessages.invalidTime(field)
                }
            }
        };
    },

    bankAgency: (field = 'agencia', minLength = 4, maxLength = 4) => {
        return {
            required: fieldMessages.required(field),
            minLength: {
                value: minLength,
                message: fieldMessages.minValue(minLength)
            },
            maxLength: {
                value: maxLength,
                message: fieldMessages.maxValue(maxLength)
            },
        };
    },

    bankAccount: (field = 'conta', minLength = 4, maxLength = 12) => {
        return {
            required: fieldMessages.required(field),
            minLength: {
                value: minLength,
                message: fieldMessages.minValue(minLength)
            },
            maxLength: {
                value: maxLength,
                message: fieldMessages.maxValue(maxLength)
            },
        };
    },
    
    bankDigit: (field = 'dígito', minLength = 1, maxLength = 1) => {
        return {
            required: fieldMessages.required(field),
            minLength: {
                value: minLength,
                message: fieldMessages.minValue(minLength)
            },
            maxLength: {
                value: maxLength,
                message: fieldMessages.maxValue(maxLength)
            },
        };
    },

    cvv: (field = 'CVV', minLength = 3, maxLength = 4) => {
        return {
            required: fieldMessages.required(field),
            minLength: {
                value: minLength,
                message: fieldMessages.minValue(minLength)
            },
            maxLength: {
                value: maxLength,
                message: fieldMessages.maxValue(maxLength)
            },
            pattern: {
                value: regex.onlyNumber,
                message: fieldMessages.onlyNumber
            }
        }
    },

    creditCard: (field = 'cartão de crédito') => {
        return {
            required: fieldMessages.required(field),
            pattern: {
                // value: regex.creditCard,
                message: fieldMessages.invalidMaleField(field)
            }
        };
    },
}

export default fieldValidations;
