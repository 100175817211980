import React, { useEffect, useState } from 'react'
// import EstablishmentStatistics from './statistics/EstablishmentStatistics'
import ListEventItems from './ListEventItems'
import eventsApi from 'api/requests/events'
import Modal from 'components/_UI/Modal'
import EventForm from 'modules/events/form/EventForm'
import Loader from 'components/_UI/Loader'
import styled from 'styled-components'
import DashCollapse from 'components/_UI/DashCollapse'
import { useDetailVisible } from 'context/dash/DetailVisible'
import Tab from '../_common/Tab'
import EventDetail from './detail/general/EventDetail'
import { Column } from 'styled/alignment/Column'
import DeleteEvent from './delete/DeleteEvent'
import { useContainerClick } from 'context/dash/ContainerClick'
import { useShowAllEvents } from 'context/dash/ShowAllEvents'
import Text from 'components/_UI/Text'
import EventItem from './EventItem'
import { useEventDetailTab } from 'context/dash/EventDetailTab'
import { FullyCentered } from 'styled/alignment/Center'
import colors from 'theme/colors'
import ShowMoreList from './ShowMoreList'
import TicketDetail from './detail/tickets/detail/TicketDetail'
import { useSearchParams } from "react-router-dom";
import routeParams from 'theme/routeParams'
import Participants from './detail/participants/Participants'
import ParticipantsList from './detail/participants/ParticipantsList'
import TicketForm from './tickets/TicketForm'
import { Row } from 'styled/alignment/Row'
import NotResultDashBoard from 'components/commons/NotResultDashBoard'
import paths from 'routes/paths'
import { useEvent } from 'context/events/Event'
import { useUser } from 'context/user/User'
import Button from 'components/_UI/Button'
import ProductsToEvent from './form/ProductsToEvent'
import useToast from 'hooks/useToast';
import ToastContainer from 'components/_UI/ToastsContainer';
import ProductDetail from './detail/products/detail/ProductDetail'
import productsApi from 'api/requests/products'
import RichTextEditor from 'components/commons/RichText'
import CollaboratorsPermissions from './detail/permissions/CollaboratorsPermissions'
import PromotionalCodes from './detail/promotionalCodes/PromotionalCodes'
import Additional from './detail/additional/Additional'
import PublishModal from './form/PublishModal'


export default function MainContent({ createEventClick, isEvents, createDraftClick, setCreateDraftClick }) {
    const [searchParams, setSearchParams] = useSearchParams();
    const showCreate = searchParams.get(routeParams.resume.create);
    const showDetail = searchParams.get(routeParams.resume.detail);
    const { toast, toasts } = useToast()


    const { containerClick } = useContainerClick();
    const { detailVisible, setDetailVisible } = useDetailVisible();
    const { showAllEvents, setShowAllEvents } = useShowAllEvents();
    const { eventDetailTab, setEventDetailTab } = useEventDetailTab();

    const { event, setEvent } = useEvent();
    const { user } = useUser();

    const [eventLoading, setEventLoading] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isCreate, setIsCreate] = useState(false);
    const [isDelete, setIsDelete] = useState(false);
    const [eventActionError, setEventActionError] = useState(false);
    const [data, setData] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isFinished, setIsFinished] = useState(false);
    const [withBackButton, setWithBackButton] = useState(false);
    const [notFound, setNotFound] = useState(false);
    const [formStep, setFormStep] = useState(1);
    const [noEstablishmentModal, setNoEstablishmentModal] = useState(false);
    const [hasTickets, setHasTickets] = useState(null);
    const [hasProducts, setHasProducts] = useState(false);
    const [originEvent, setOriginEvent] = useState();
    const [dropdownLoading, setDropdownLoading] = useState();
    const [productsLoading, setProductsLoading] = useState();
    const [availableProducts, setAvailableProducts] = useState();
    const [publishModal, setPublishModal] = useState(false);


    useEffect(async () => {
        if (showDetail) {
            renderDetail(showDetail)
        }
        if (showCreate == 1) {
            setIsCreate(true)
        }
        
        if (createEventClick || createDraftClick) {
            setIsCreate(true)
            return
        }
        await Promise.all([getEvents(), getAvailableProducts()])

    }, [createEventClick, createDraftClick])

    const handleTabClick = (eventDetailTab) => {
        setEventDetailTab(eventDetailTab)
    };

    function createEvent() {
        const isEstablishment = user?.role?.id == import.meta.env.VITE_ESTABLISHMENT_ROLE_ID
        if (isEstablishment && !user?.establishments.length) {
            setNoEstablishmentModal(true);
            return;
        }

        setEvent(null)
        setIsCreate(true)
    }


    function renderContent(tabName) {
        return (

            <>
                {/* {tabName === 'statistics' && <ContentDash><h2>Estatísticas</h2></ContentDash>} */}
                {tabName === 'detail' &&
                    <ContentDash>
                        <EventDetail
                            item={showDetail ? showDetail : detailVisible?.slug}
                            setHasTickets={setHasTickets}
                            hasTickets={hasTickets}
                            withBackButton={withBackButton}
                            setHasProducts={setHasProducts}
                            hasProducts={hasProducts}
                        />
                    </ContentDash>}

                {tabName === 'tickets' && hasTickets &&
                    <ContentDash><TicketDetail data={showDetail ? showDetail : detailVisible.slug} /></ContentDash>
                }
                {tabName === 'products' && hasProducts &&
                    <ContentDash><ProductDetail data={showDetail ? showDetail : detailVisible.slug} /></ContentDash>
                }
                {tabName === 'participant' &&
                    <ContentDash>
                        <ParticipantsList data={showDetail ? showDetail : detailVisible.slug} />
                    </ContentDash>
                }
                {tabName === 'permissions' &&
                    <ContentDash>
                        <CollaboratorsPermissions data={showDetail ? showDetail : detailVisible.slug} />
                    </ContentDash>
                }
                {tabName === 'promotionalCode' &&
                    <ContentDash>
                        <PromotionalCodes data={showDetail ? showDetail : detailVisible.slug} />
                    </ContentDash>
                }
                {tabName === 'additional' &&
                    <ContentDash>
                        <Additional data={showDetail ? showDetail : detailVisible.slug} />
                    </ContentDash>
                }
            </>
        );

    }

    async function getEvents() {
        setLoading(true)
        try {
            const { data } = await eventsApi.myEvents()
            setData(data?.data)

            setLoading(false)

            if (data?.data?.expired?.total > 0 || data?.data?.now?.total > 0 || data?.data?.next?.total > 0) {
                isEvents()
            }

            if (!data?.now?.total > 0 || !data?.next?.total > 0 || !data?.expired?.total > 0) {
                setNotFound(true)
            }
        } catch (error) {
            console.log('error')
        } finally {
            setLoading(false)
        }
    }


    function handleSucess() {
        getEvents()
        toast.success('Evento excluido com sucesso', {
            position: 'top-center'
        })
    }

    function renderDetail(slug) {
        renderContent('detail')
        setDetailVisible(slug)
    }

    async function getEventBySlug(item) {

        if (item.from) {
            setEventLoading(true)
        } else {
            setDropdownLoading(true);
        }

        try {
            const { data } = await eventsApi.getBySlug(item.slug)
            setIsEdit(data?.data)
        } catch (error) {
            console.log('error')
        } finally {
            setDropdownLoading(false);
            setTimeout(() => {
                setEventLoading(false)
            }, 400);
        }
    }

    function activeActionClicked(item) {
        setDetailVisible(false)
        setIsCreate(false)

        if (item.action === 'edit') {
            getEventBySlug({ slug: item?.slug, from: item.from });
            setFormStep(1);

            return
        }

        if (item.action === 'delete') {
            setIsDelete(item);
        }

        if (item.action === 'publish') {
            setPublishModal(item);
        }
    }

    async function getAvailableProducts() {
        if (!!availableProducts) {
            return
        }

        setProductsLoading(true);

        try {
            const { data } = await productsApi.get();
            const productsWithFlag = data?.data.map(el => ({ ...el, is_template: true }))
            setAvailableProducts(productsWithFlag);

        } catch (error) {
            setAvailableProducts(false);
        } finally {
            setProductsLoading(true);
        }
    }

    async function onEventSucces(data, from) {

        const isEdit = from === 'edit';
        const withTickets = data?.products?.filter(el => el.type === 'ticket')

        setOriginEvent(from)

        getEvents()

        if (isEdit) {

            if (withTickets?.length || data?.has_tickets) {
                setFormStep(2);
                return
            }

            if (!withTickets?.length && !data?.has_tickets && !availableProducts?.length) {
                setIsEdit(false);
                setDetailVisible(data);
                setFormStep(0);
                setCreateDraftClick(0);
                return
            }

            if (!withTickets?.length && !data?.has_tickets && availableProducts?.length) {
                setDetailVisible(false);
                setFormStep(3);
                return
            }
        }

        if (data?.has_tickets) {
            setFormStep(2);
            return
        }

        if (!withTickets?.length && !data?.has_tickets && !availableProducts?.length) {
            setIsCreate(false);
            setDetailVisible(data);
            setCreateDraftClick(0)
            setFormStep(0);
            return
        }

        if (!withTickets?.length && !data?.has_tickets && availableProducts?.length) {
            setDetailVisible(false);
            setFormStep(3);
            return
        }


        setIsCreate(false);
        setIsEdit(false);
        setDetailVisible(data);
        setFormStep(0);
        // getEvents();
    }

    function onTicketSuccess() {
        if (availableProducts.length) {
            setFormStep(3);
            return
        }

        setCreateDraftClick(0)
        setIsCreate(false);
        setIsEdit(false);
        setDetailVisible(event);
    }

    function onProductsToEventSuccess() {
        setIsCreate(false);
        setIsEdit(false);
        setDetailVisible(event);
        setCreateDraftClick(0)
        getEvents();
    }

    function onEventPublished(eventName) {
        toast.success(`O evento "${eventName}" foi publicado com sucesso!`, {
            position: 'top-center'
        });
        setIsCreate(false);
        setIsEdit(false);
        getEvents();
    }

    function onCloseCollapse() {
        setCreateDraftClick(0);
        setWithBackButton(false)
        setIsEdit(false)
        setIsCreate(false)
        setEventDetailTab('detail')
        setDetailVisible(false)
        setFormStep(1)
        

        setSearchParams((prevSearchParams) => {
            const newSearchParams = new URLSearchParams(prevSearchParams);

            Object.values(routeParams.resume).map(el => {
                newSearchParams.delete(el)
            })

            return newSearchParams;
        });
        setEventActionError(false)
        setShowAllEvents({ ...showAllEvents, visible: false })
    }

    // function eventCreated(data) {
    //     setFormStep(2);
    // }

    return (
        <>
            {
                loading ?
                    <StyledLoading>
                        <Loader />
                    </StyledLoading> : (
                        <>
                            {data?.now?.total > 0 || data?.next?.total > 0 || data?.expired?.total > 0 || data?.draft?.total > 0 ? (
                                <>
                                    {/* <EstablishmentStatistics draft={data?.draft} expired={data?.expired} next={data?.next} /> */}

                                    {
                                        data?.now?.total ? (
                                            <ListEventItems
                                                title='Eventos de hoje'
                                                type='now'
                                                data={data.now}
                                                dropdownLoading={dropdownLoading}
                                                onEventClick={e => setDetailVisible(e)}
                                                onDropdownAction={(e) => activeActionClicked(e)}
                                            />
                                        ) : null
                                    }

                                    {
                                        data?.draft?.total ? (
                                            <ListEventItems
                                                title='Rascunho'
                                                type='draft'
                                                data={data.draft}
                                                dropdownLoading={dropdownLoading}
                                                onEventClick={e => setDetailVisible(e)}
                                                onDropdownAction={(e) => activeActionClicked(e)}
                                            />
                                        ) : null
                                    }


                                    {
                                        data?.next?.total ? (
                                            <ListEventItems
                                                title='Próximos Eventos'
                                                type='next'
                                                data={data.next}
                                                dropdownLoading={dropdownLoading}
                                                onEventClick={e => setDetailVisible(e)}
                                                onDropdownAction={(e) => activeActionClicked(e)}
                                            />
                                        ) : null
                                    }

                                    {
                                        data?.expired?.total ? (
                                            <ListEventItems
                                                title='Finalizados'
                                                type='expired'
                                                data={data.expired}
                                                dropdownLoading={dropdownLoading}
                                                onEventClick={e => setDetailVisible(e)}
                                                onDropdownAction={(e) => activeActionClicked(e)}
                                                expired
                                            />
                                        ) : null
                                    }
                                </>
                            ) : (
                                notFound &&
                                <NotResultDashBoard
                                    ilustration={'events'}
                                    textButton={'Criar primeiro evento'}
                                    onClick={() => createEvent()}
                                    text={'Notei que você ainda não possui eventos. Que tal criar seu primeiro evento e ter uma experiência incrível?'}
                                />
                            )}

                            {
                                noEstablishmentModal && (
                                    <Modal isOpen={!!noEstablishmentModal} onClose={() => setNoEstablishmentModal(false)} >
                                        <Text name='highlight' marginTop={'10px'} marginBottom={'10px'}>
                                            Você precisa ter um Estabelecimento criado para cadastrar um evento.
                                        </Text>
                                        <Button path={paths.dashboard.profile}>
                                            Criar Estabelecimento
                                        </Button>
                                    </Modal>
                                )
                            }

                        </>

                    )
            }

            <DeleteEvent
                isDelete={isDelete}
                onSuccess={handleSucess}
                onClose={(e) => setIsDelete(e)}
            />

            <ToastContainer toasts={toasts} />

            {
                (!!isEdit || isCreate) &&
                <DashCollapse
                    visible={!!isEdit || isCreate}
                    onClose={() => onCloseCollapse()}
                >
                    {

                        eventLoading
                            ? <EventLoaderContainer>
                                <Loader />
                            </EventLoaderContainer>
                            : (
                                formStep === 1 &&
                                <EventForm
                                    isDraft={createDraftClick}
                                    setCreateDraftClick={setCreateDraftClick}
                                    edit={isEdit}
                                    onLoading={(e) => setEventLoading(e)}
                                    onSuccess={(data, from) => onEventSucces(data, from)}
                                />
                            )
                    }

                    {
                        formStep === 2 && (
                            <TicketForm
                                isDraft={createDraftClick}
                                edit={originEvent === 'edit'}
                                availableProducts={availableProducts}
                                onSuccess={(data) => onTicketSuccess(data)}
                            />
                        )
                    }

                    {
                        formStep === 3 && (
                            <ProductsToEvent
                                isDraft={createDraftClick}
                                edit={originEvent === 'edit'}
                                availableProducts={availableProducts}
                                onSuccess={(data) => onProductsToEventSuccess(data)}
                            />
                        )
                    }

                </DashCollapse>
            }

            {/* <Modal size='large' isOpen={!!isEdit} onClose={() => setIsEdit(false)}>
                <EventForm edit={isEdit} />
            </Modal> */}

            <DashCollapse
                visible={!!detailVisible}
                onClose={() => onCloseCollapse()}
                deleted={true}
                edit={true}
                clickedAction={(e) => activeActionClicked(e)}
            >
                <Tab toogleTab={handleTabClick} selectedTab={eventDetailTab} hasTickets={hasTickets} hasProducts={hasProducts} />
                {detailVisible && renderContent(eventDetailTab)}
            </DashCollapse>

            <DashCollapse
                visible={!!showAllEvents?.visible}
                onClose={() => onCloseCollapse()}
                widthOnDesktop={'70%'}
            >

                {showAllEvents?.visible && (
                    <ShowMoreList
                        withBackButton={withBackButton}
                        onEventClick={
                            (e) => {
                                setDetailVisible(e)
                                setWithBackButton(true)
                            }
                        }
                        onDropdownAction={(e) => activeActionClicked(e)}
                    />
                )}
            </DashCollapse>

            {
                publishModal && (
                    <PublishModal
                        visible={publishModal}
                        data={publishModal}
                        onClose={() => setPublishModal(false)}
                        onPublished={(e) => onEventPublished(e)}
                    />
                )
            }
        </>
    )
}

const StyledLoading = styled(FullyCentered)`   
    width: 100%;
    height: 500px;

`;

const ContentDash = styled(Column)`
    width: 100%;
    height: 100vh;
    padding: 5px;
    margin-top: 20px;
`

const EventLoaderContainer = styled(FullyCentered)`
    width: 100%;
    height: 100%;
`;
